import React, { useState } from 'react';

const PricingComponent = () => {
  const [billingCycle, setBillingCycle] = useState('annually');

  return (
    <section className="bg-white text-gray-700 min-h-screen flex flex-col items-center p-6 sm:p-8 sm:mt-32 md:mt-40 lg:mt-48 mt-[77rem] relative overflow-hidden">

      {/* Floating Background and Headings */}
      <div className="flex flex-col items-center mb-16">
        
        {/* Top Gradient Background */}
        <div className="absolute inset-x-0 top-0 h-64 sm:h-80 bg-gradient-to-b from-green-100 to-transparent rounded-b-full"></div>

        {/* Title Inside Floating Background */}
        <h2 className="text-green-600 text-2xl sm:text-4xl font-semibold mb-2 sm:mb-4 text-center z-10">Flexible Plans to Suit Your Needs</h2>
        <p className="text-gray-500 text-center mb-4 sm:mb-8 z-10 max-w-lg">
          Whether you're a casual driver or ready to hit the road full-time, pick a plan that aligns with your goals.
        </p>
        
        <div className="flex flex-col items-center justify-center text-center z-10">
          <div className="text-5xl sm:text-6xl mb-4 font-sans">👍</div>
          <h1 className="text-md sm:text-lg text-gray-500 mb-1 sm:mb-2 font-sans">WE'RE STILL</h1>
          <h2 className="text-2xl sm:text-3xl font-bold text-green-600 mb-4 font-sans">Cooking Our Website</h2>
          <p className="text-gray-600 mb-6 sm:mb-8 font-sans">
            We are going to launch our website <br /> Very Soon. Stay Tuned.
          </p>
          <button className="flex items-center px-5 sm:px-6 py-2 sm:py-3 bg-green-400/20 text-green-700 font-sans rounded-full shadow-lg hover:bg-green-500 hover:text-white transition-all duration-200">
            Coming soon..
          </button>
        </div>

        {/* Bottom Gradient Background */}
        <div className="absolute inset-x-0 bottom-0 h-64 sm:h-80 bg-gradient-to-t from-green-100 to-transparent rounded-t-full"></div>
        
        {/* Price Notes Section */}
        <div className="z-10 text-center mt-10 sm:mt-16 px-4 sm:px-0">
          <h3 className="text-sm sm:text-base font-semibold text-gray-500 mb-2 sm:mb-3 font-sans">Price Notes</h3>
          <p className="text-gray-500 max-w-md mx-auto font-sans text-xs sm:text-sm">
            Get clear, upfront pricing on all services – no surprises. Transparent, easy-to-understand rates for every journey.
          </p>
        </div>
      </div>
    </section>
  );
};

export default PricingComponent;
