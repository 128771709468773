import React, { useState, useEffect } from 'react';
import mobile_auto from '../assets/img/logo.png';
import '@fortawesome/fontawesome-free/css/all.min.css';

const testimonials = [
  {
    text: "Finding an affordable auto taxi nearby has never been easier! I use this app daily for my commutes.",
    image: "/path/to/image1.jpg",
    name: "Aarav Kumar",
    location: "Gandhipuram, Coimbatore",
    rating: 5,
  },
  {
    text: "The best auto taxi app for quick bookings and reliable rides. Highly recommended for safe travel in the city.",
    image: "/path/to/image2.jpg",
    name: "Meera Reddy",
    location: "RS Puram, Coimbatore",
    rating: 4,
  },
  {
    text: "Amazing service with competitive rates. Perfect for local and outstation travel from Coimbatore!",
    image: "/path/to/image3.jpg",
    name: "Rajesh Nair",
    location: "Saibaba Colony, Coimbatore",
    rating: 5,
  },
  {
    text: "This app offers the cheapest and best taxi services in Coimbatore. Easy to book and very reliable.",
    image: "/path/to/image4.jpg",
    name: "Anjali Singh",
    location: "Peelamedu, Coimbatore",
    rating: 5,
  },
  {
    text: "The best choice for anyone needing a safe and affordable taxi in Coimbatore. I use it every day!",
    image: "/path/to/image5.jpg",
    name: "Deepak Mohan",
    location: "Saravanampatti, Coimbatore",
    rating: 4,
  },
  {
    text: "Booking a jungle safari was easy and affordable. The experience was safe and family-friendly!",
    image: "/path/to/image6.jpg",
    name: "Priya Balan",
    location: "Thudiyalur, Coimbatore",
    rating: 5,
  },
  {
    text: "My go-to app for outstation taxis from Coimbatore! Great service and reasonable rates per km.",
    image: "/path/to/image7.jpg",
    name: "Hari Krishnan",
    location: "Ukkadam, Coimbatore",
    rating: 4,
  },
  {
    text: "Safe safari tours in Coimbatore are just a click away. The app makes it so convenient!",
    image: "/path/to/image8.jpg",
    name: "Sita Nair",
    location: "Kuniamuthur, Coimbatore",
    rating: 5,
  },
  {
    text: "Reliable drivers and great customer support. This app is ideal for taxi needs in Coimbatore.",
    image: "/path/to/image9.jpg",
    name: "Rohit Menon",
    location: "Goundampalayam, Coimbatore",
    rating: 5,
  },
  {
    text: "I found a lady driver easily for my commute. Very professional and punctual. Excellent service!",
    image: "/path/to/image10.jpg",
    name: "Vani Priya",
    location: "Kovaipudur, Coimbatore",
    rating: 4,
  },
  {
    text: "Affordable prices for local taxis in Coimbatore. Great service and always available 24/7.",
    image: "/path/to/image11.jpg",
    name: "Arjun Srinivasan",
    location: "Race Course, Coimbatore",
    rating: 5,
  },
  {
    text: "The best app for booking auto taxis within minutes. I rely on it for all my daily rides.",
    image: "/path/to/image12.jpg",
    name: "Madhuri Rao",
    location: "RS Puram, Coimbatore",
    rating: 5,
  },
  {
    text: "Found a great safari tour for my family in Coimbatore. Safe, well-organized, and affordable.",
    image: "/path/to/image13.jpg",
    name: "Vikram Sinha",
    location: "Vadavalli, Coimbatore",
    rating: 5,
  },
  {
    text: "Excellent for quick outstation bookings and reliable service across Coimbatore. Highly recommend!",
    image: "/path/to/image14.jpg",
    name: "Nisha Gupta",
    location: "Singanallur, Coimbatore",
    rating: 4,
  },
  {
    text: "Easy to book, safe, and economical for local travel. Great taxi app for Coimbatore residents.",
    image: "/path/to/image15.jpg",
    name: "Ravi Shankar",
    location: "Koundampalayam, Coimbatore",
    rating: 5,
  },
];

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 5000);
    return () => clearInterval(interval);
  }, [currentIndex]);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
    );
  };

  const renderStars = (rating) => {
    return Array(5)
      .fill()
      .map((_, index) => (
        <i
          key={index}
          className={`fas fa-star ${index < rating ? 'text-yellow-500' : 'text-gray-300'}`}
        ></i>
      ));
  };

  return (
    <div className="relative w-full mt-8 px-4 sm:px-0">
      <div className="relative overflow-hidden rounded-lg max-w-xs sm:max-w-md mx-auto shadow-md">
        {testimonials.map((testimonial, index) => (
          <div
            key={index}
            className={`testimonial-slide ${index === currentIndex ? 'block' : 'hidden'} transition duration-700 ease-in-out`}
          >
            <div className="bg-green-50 p-4 sm:p-6 rounded-lg shadow-lg mx-auto relative">
              <p className="text-gray-600 text-sm sm:text-base text-center font-sans ">{testimonial.text}</p>
              <div className="flex items-center justify-center mt-4">
                <img
                  src={mobile_auto}
                  alt={testimonial.name}
                  className="w-8 h-8 sm:w-10 sm:h-10 rounded-full"
                />
                <div className="ml-3 text-left">
                  <h4 className="font-semibold text-gray-800 text-sm sm:text-base font-sans ">{testimonial.name}</h4>
                  <div className="flex">
                    {renderStars(testimonial.rating)}
                  </div>
                </div>
              </div>

              <button
                type="button"
                onClick={handlePrev}
                className="absolute top-1/2 left-2 transform -translate-y-1/2 px-2 py-1"
              >
                <svg className="w-4 h-4 text-gray-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1 1 5l4 4"/>
                </svg>
              </button>
              <button
                type="button"
                onClick={handleNext}
                className="absolute top-1/2 right-2 transform -translate-y-1/2 px-2 py-1"
              >
                <svg className="w-4 h-4 text-gray-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4"/>
                </svg>
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
