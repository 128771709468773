import React, { useState } from "react";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai"; // Import icons
import m_auto from "../assets/img/mobile_home.png";
import logo from "../assets/img/logo.png";
import logo_1 from "../assets/img/logo_1.png";
import TypewriterEffect from "../hooks/typewriteeffect.js";
import MobileHowItWorks from "./how_its_work.js";
import MobilePrice from "./price.js";
import MobileAboutUS from "./mobile_about_us.js";
import MobileContactUS from "./contact_us.js";

const LandingPage = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div>
            <div 
                className="min-h-screen" 
                style={{ 
                    backgroundImage: `url(${m_auto})`, 
                    backgroundSize: 'cover', 
                    backgroundPosition: 'center' 
                }}
            >
                {/* Navbar */}
                <nav className="relative flex justify-between items-center py-6 px-4 md:px-8">
                    {/* Left Side Logo */}
                    <div className="flex items-center ml-3">
                        <img src={logo} alt="Logo" className="w-10 h-10" />
                    </div>
                    <div className="-mb-5">
                        <img src={logo_1} alt="Logo"className="w-40 h-30" />
                    </div>

                    {/* Right Slide-in Menu */}
                    <div 
                        className={`fixed top-0 right-0 h-full w-72 bg-white shadow-lg p-6 backdrop-blur-lg transform ${isOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out z-50`}
                    >
                        <div className="flex items-center justify-between">
                            <span className="font-bold text-xl text-gray-800 font-sans">Safe Safari</span>
                            <AiOutlineClose
                                className="text-2xl cursor-pointer text-gray-800"
                                onClick={() => setIsOpen(false)}
                            />
                        </div>
                        <nav className="mt-8 space-y-6">
                            <a href="#" className="block text-gray-800 hover:text-green-600 text-lg font-medium font-sans">Our Services</a>
                            <a href="#" className="block text-gray-800 hover:text-green-600 text-lg font-medium font-sans">Plant Care</a>
                            <a href="#" className="block text-gray-800 hover:text-green-600 text-lg font-medium font-sans">Pricing</a>
                            <a href="#" className="block text-gray-800 hover:text-green-600 text-lg font-medium font-sans">About</a>
                        </nav>
                        <div className="mt-10 border-t border-gray-200 pt-6">
                            <a href="#" className="block text-gray-500 hover:text-gray-700 text-base font-sans">Contact Us</a>
                            <a href="#" className="block text-gray-500 hover:text-gray-700 text-base mt-2 font-sans">Help & Support</a>
                        </div>
                    </div>

                    {/* Right Side Menu Icon */}
                    <AiOutlineMenu
                        className="text-2xl text-gray-800 cursor-pointer md:hidden mr-3"
                        onClick={() => setIsOpen(true)}
                    />
                </nav>


                {/* Hero Section */}
                <section className="px-4 md:px-8 mt-6 flex flex-col lg:flex-row items-center lg:items-start justify-between lg:max-w-5xl xl:max-w-6xl mx-auto">
                    {/* Transparent Box Wrapper */}
                    <div className="w-full lg:w-[45%] flex flex-col items-center text-center mt-3 lg:mt-0 lg:mr-9 bg-white/10 p-4 lg:p-6 rounded-lg backdrop-blur-md">
                        {/* Main Heading */}
                        <header className="p-5 text-center text-green-100">
                            <h1 className="text-2xl sm:text-3xl md:text-4xl font-sans font-bold">
                                Welcome to Coimbatore’s Premier Auto Services
                            </h1>
                        </header>
                        <div className="w-full h-full flex justify-center items-center">
                            <h1 id="typewriter" className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-green-50 mb-4">
                                <TypewriterEffect />
                            </h1>
                        </div>

                        {/* Subtext */}
                        <p className="mt-4 text-white text-sm sm:text-base md:text-lg max-w-md mb-4 font-sans">
                            Download Safe Safari app! Available on the App Store and Play Store, drive your Auto solo, accept ride requests, and maximize your earnings!
                        </p>

                        {/* Store Buttons */}
                        <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-3 sm:space-y-0">
                            <button 
                                type="button" 
                                className="flex items-center justify-center w-40 sm:w-48 text-white bg-black h-12 sm:h-14 rounded-lg"
                            >
                                <div className="mr-3">
                                    <svg viewBox="30 336.7 120.9 129.2" width="30">
                                        <path fill="#FFD400" d="M119.2,421.2c15.3-8.4,27-14.8,28-15.3c3.2-1.7,6.5-6.2,0-9.7c-2.1-1.1-13.4-7.3-28-15.3l-20.1,20.2L119.2,421.2z"></path>
                                        <path fill="#FF3333" d="M99.1,401.1l-64.2,64.7c1.5,0.2,3.2-0.2,5.2-1.3c4.2-2.3,48.8-26.7,79.1-43.3L99.1,401.1L99.1,401.1z"></path>
                                        <path fill="#48FF48" d="M99.1,401.1l20.1-20.2c0,0-74.6-40.7-79.1-43.1c-1.7-1-3.6-1.3-5.3-1L99.1,401.1z"></path>
                                        <path fill="#3BCCFF" d="M99.1,401.1l-64.3-64.3c-2.6,0.6-4.8,2.9-4.8,7.6c0,7.5,0,107.5,0,113.8c0,4.3,1.7,7.4,4.9,7.7L99.1,401.1z"></path>
                                    </svg>
                                </div>
                                <div>
                                    <div className="text-xs">GET IT ON</div>
                                    <div className="-mt-1 font-sans text-lg sm:text-xl font-semibold">Google Play</div>
                                </div>
                            </button>
                            <button 
                                type="button" 
                                className="flex items-center justify-center w-40 sm:w-48 text-white bg-black h-12 sm:h-14 rounded-lg"
                            >
                                <div className="mr-3">
                                    <svg viewBox="0 0 384 512" width="30">
                                        <path fill="currentColor" d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"></path>
                                    </svg>
                                </div>
                                <div>
                                    <div className="text-xs">Download on the</div>
                                    <div className="-mt-1 font-sans text-lg sm:text-xl font-semibold">App Store</div>
                                </div>
                            </button>
                        </div>
                    </div>
                </section>
            </div>
            <MobileHowItWorks />
            <MobilePrice />
            <MobileAboutUS />
            <MobileContactUS /> 
        </div>
    );
};

export default LandingPage;
