import React from "react";

const ContactUs = () => {
  return (
    <div id="contact-us" className="bg-gray-100 min-h-screen flex items-center justify-center p-6">
      <div className="bg-white shadow-lg rounded-lg max-w-3xl w-full">
        <div className="p-6">
          <h1 className="text-3xl font-bold text-center text-gray-800 mb-4">Contact Us</h1>
          <p className="text-gray-600 text-center mb-8">
            Feel free to reach out to us for any queries or support.
          </p>

          {/* Merchant Details */}
          <div className="space-y-4">
            <div>
              <h2 className="text-xl font-semibold text-gray-800">Address:</h2>
              <p className="text-gray-600">
              SF NO.157, ANP VILLAGE, KOTTAIPUDUR, UKKADAM, UKKADAM,
              COIMBATORE, COIMBATORE, TAMIL NADU, 641001, COIMBATORE SOUTH, Tamil Nadu,
              PIN: 641001
              </p>
            </div>
            <div>
              <h2 className="text-xl font-semibold text-gray-800">Telephone No:</h2>
              <p className="text-gray-600">7530015700</p>
            </div>
            <div>
              <h2 className="text-xl font-semibold text-gray-800">E-Mail ID:</h2>
              <p className="text-gray-600">safesafarimetreauto2025@gmail.com</p>
            </div>
          </div>

          {/* Contact Form */}
          <div className="mt-8">
            <h2 className="text-2xl font-bold text-gray-800 text-center mb-4">Send us a Message</h2>
            <form className="space-y-4">
              <div>
                <label className="block text-gray-700 font-semibold mb-2" htmlFor="name">
                  Your Name
                </label>
                <input
                  type="text"
                  id="name"
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
                  placeholder="Enter your name"
                />
              </div>
              <div>
                <label className="block text-gray-700 font-semibold mb-2" htmlFor="email">
                  Your Email
                </label>
                <input
                  type="email"
                  id="email"
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
                  placeholder="Enter your email"
                />
              </div>
              <div>
                <label className="block text-gray-700 font-semibold mb-2" htmlFor="message">
                  Message
                </label>
                <textarea
                  id="message"
                  rows="5"
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
                  placeholder="Write your message"
                ></textarea>
              </div>
              <button
                type="submit"
                className="w-full bg-blue-500 text-white font-semibold py-3 rounded-lg hover:bg-blue-600 transition duration-300"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
