import React, { useEffect, useState } from 'react';

const TypewriterEffect = () => {
  const phrases = ["Download app,", "Start Safari,", "Earn money!"];
  const [text, setText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [loop, setLoop] = useState(0);
  const [typingSpeed, setTypingSpeed] = useState(150);

  useEffect(() => {
    const handleTyping = () => {
      const currentPhrase = phrases[loop % phrases.length];
      setText(isDeleting ? currentPhrase.substring(0, text.length - 1) : currentPhrase.substring(0, text.length + 1));

      if (!isDeleting && text === currentPhrase) {
        setIsDeleting(true);
        setTypingSpeed(70);
      } else if (isDeleting && text === '') {
        setIsDeleting(false);
        setLoop(loop + 1);
        setTypingSpeed(200);
      }
    };

    const timer = setTimeout(handleTyping, typingSpeed);

    return () => clearTimeout(timer);
  }, [text, isDeleting, loop, typingSpeed, phrases]);

  return (
    <div className="w-full h-full flex justify-center items-center">
      <h1 className="text-3xl sm:text-4xl lg:text-5xl xl:text-6xl font-bold text-green-700 mb-4 font-sans">{text}</h1>
    </div>
  );
};

export default TypewriterEffect;