import React from 'react';

const RefundPolicy = () => {
    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-4xl font-bold mb-6 text-center">Cancellation & Refund Policy</h1>

            <h3>Last updated on 23-01-2025 19:57:24</h3>

            <section className="mb-6">
                <p className="text-gray-700">
                    MATHINA BANU A believes in helping its customers as far as possible, and has therefore a liberal cancellation policy. Under this policy:
                </p>
                <ul className="list-disc list-inside text-gray-700 mt-4">
                    <li>
                        Cancellations will be considered only if the request is made immediately after placing the order. However, the cancellation request may not be entertained if the orders have been communicated to the vendors/merchants and they have initiated the process of shipping them.
                    </li>
                    <li>
                        MATHINA BANU A does not accept cancellation requests for perishable items like flowers, eatables etc. However, refund/replacement can be made if the customer establishes that the quality of product delivered is not good.
                    </li>
                    <li>
                        In case of receipt of damaged or defective items please report the same to our Customer Service team. The request will, however, be entertained once the merchant has checked and determined the same at his own end. This should be reported within 7 days of receipt of the products. In case you feel that the product received is not as shown on the site or as per your expectations, you must bring it to the notice of our customer service within 7 days of receiving the product. The Customer Service Team after looking into your complaint will take an appropriate decision.
                    </li>
                    <li>
                        In case of complaints regarding products that come with a warranty from manufacturers, please refer the issue to them. In case of any Refunds approved by the MATHINA BANU A, it’ll take 6-8 days for the refund to be processed to the end customer.
                    </li>
                </ul>
            </section>

            <footer className="text-center text-gray-600 mt-8">
                <p>
                    All concerns or communications relating to these Terms must be communicated to us using the contact information provided on this website.
                </p>
                <p className="mt-4">&copy; {new Date().getFullYear()} SAFE SAFARI. All rights reserved.</p>
            </footer>
        </div>
    );
};

export default RefundPolicy;
