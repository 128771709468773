import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "@fontsource/roboto"; // Defaults to weight 400
import "./App.css";

// Desktop Views
import Home from "./pages/home.js";
import HowItWorks from "./pages/how_to_work.js";
import PricingCard from "./components/price.js";
import AboutUS from "./pages/about_us.js";
import ContactUS from "./pages/ContactUs.js";
import FooterContactUS from "./pages/contact_us.js";
import TermsConditions from "./pages/treams_condition.js";
import Subscribe from "./components/subscribe.js";
import RefundPolicy from "./pages/refund_policy.js";

// Mobile Views
import MobileHome from "./mobile_view/Home.js";
import MobileHowItWorks from "./mobile_view/how_its_work.js";
import MobilePrice from "./mobile_view/price.js";
import MobileAboutUS from "./mobile_view/mobile_about_us.js";
import MobileContactUS from "./mobile_view/contact_us.js";

// Hooks
import useResponsive from "./hooks/useResponsive";

// SEO
import SEO from "./pages/seo.js";

function App() {
  const { isMobile, isTablet } = useResponsive();

  // Component Mapping
  const isMobileView = isMobile || isTablet;
  
  // Define route-based components for desktop and mobile views
  const DesktopRoutes = () => (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact-us" element={<ContactUS />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </>
  );

  const MobileRoutes = () => (
    <>
      <Routes>
        <Route path="/" element={<MobileHome />} />
        <Route path="/contact-us" element={<ContactUS />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="*" element={<MobileHome />} />
      </Routes>
    </>
  );

  return (
    <Router>
      <div className="App">
        <SEO />
        {/* Render mobile or desktop routes based on device type */}
        {isMobileView ? <MobileRoutes /> : <DesktopRoutes />}
      </div>
    </Router>
  );
}

export default App;
